import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, withCtx as _withCtx, createVNode as _createVNode, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-6b4e59bd"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 0,
  class: "tab"
}
const _hoisted_3 = { class: "selectList" }
const _hoisted_4 = { class: "table" }
const _hoisted_5 = { class: "buttonList" }
const _hoisted_6 = { class: "left tableTitle" }
const _hoisted_7 = { class: "right" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_tab_pane = _resolveComponent("a-tab-pane")!
  const _component_a_tabs = _resolveComponent("a-tabs")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.tabList.length !== 0)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_a_tabs, {
            activeKey: _ctx.activeKey,
            onChange: _ctx.tabChange
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tabList, (item) => {
                return (_openBlock(), _createBlock(_component_a_tab_pane, {
                  key: item.key,
                  tab: item.title
                }, null, 8, ["tab"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["activeKey", "onChange"])
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "selectList", {}, undefined, true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.title + _ctx.propsTitle), 1),
        _createElementVNode("div", _hoisted_7, [
          _renderSlot(_ctx.$slots, "buttonList", {}, undefined, true)
        ])
      ]),
      _renderSlot(_ctx.$slots, "table", {}, undefined, true)
    ])
  ]))
}