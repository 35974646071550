
// import Data from '@/model/common/Data'
import { defineComponent, computed, PropType } from 'vue'
import { useRoute } from 'vue-router'
import { TabItem } from '@/types/comm'
export default defineComponent({
  props: {
    tabList: {
      type: Array as PropType<TabItem[]>,
      default: () => {
        return []
      },
    },
    activeKey: [String, Number],
    propsTitle: {
      type: String,
      default: '',
    },
  },
  emits: ['update:activeKey'],
  setup(props, { emit }) {
    let route = useRoute()
    //面包屑数据从路由中获取
    const title = computed(() => route.matched[route.matched.length - 1].meta.title)
    const tabChange = (key: unknown) => {
      emit('update:activeKey', key)
    }
    if (props.tabList?.length !== 0 && !props.activeKey) {
      tabChange(props.tabList[0]?.key)
    }
    // if (!props.activeKey) {
    //   tabChange(props.activeKey)
    // }
    return {
      title,
      tabChange,
    }
  },
})
